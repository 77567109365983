.scrollBtn{
  display: flex;
  justify-content: flex-end;
  .scroll_to_top {
    position: fixed;
    bottom: 0px;
    // right: 20px;
    background-color: #ebe8e8;
    border: none;
    border-radius: 50%;
    width: 3rem ;
    height: 3rem;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    margin-bottom: 5rem;
  }
  
  .scroll_to_top:focus {
    outline: none;
  }
  
  .scroll_to_top:hover {
    background-color: #c4c3c3;
  }
}

