.navbar {
  position: sticky;
  top: 0;
  // background: rgba(255, 255, 255, 0.2); /* Transparent white background */
  // backdrop-filter: blur(10px); /* Apply blur to the background */
  // -webkit-backdrop-filter: blur(10px); /* Safari support */
  background: white;
  z-index: 22;
}
.navbar-nav .nav-link {
  margin-right: 20px;
  font-weight: 700;
  font-size: 1.1rem;
}

nav {
  .header-container {
    img:hover {
      cursor: pointer;
    }

    .navbar-collapse {
      margin-right: 80px;
    }
  }
}

@media (max-width: 1200px) {
  .navbar {
    padding-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    width: 90%;
    z-index: 1;
  }

  nav {
    .header-container {
      .navbar-collapse {
        margin-left: 10px;
        background-color: white;
        margin-top: -2vh;
        width: 100%;
        position: relative;

        .navbar-nav {
          position: absolute;
          background-color: white;
          margin-top: -1vh;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .navbar {
    .header-container {
      img {
        width: 5rem !important;
      }
    }
  }
}
